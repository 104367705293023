import React from "react"
import { introSection } from "./style"

const IntroSection = () => {
  return (
    <section css={introSection}>
      <h1>
        Get <span>Free</span> Reports
      </h1>
      <p>
        Lorem ipsum dolor sit amet consectetur. Vestibulum pellentesque nulla
        interdum nulla vivamus quis. Lacus est fames dui tristique risus.
        Convallis faucibus quisque vel proin congue pulvinar vitae fames id.
      </p>
    </section>
  )
}

export default IntroSection
